import React, { useState, useEffect } from 'react';
import { ArrowRight, Code, Brain, Zap, ChevronLeft, ChevronRight } from 'lucide-react';
import projects from './projects.json';

const LandingPage = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCurrentSlide((prevSlide) => (prevSlide + 1) % projects.length);
  //   }, 20_000);
  //   return () => clearInterval(timer);
  // }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % projects.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + projects.length) % projects.length);
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <header className="bg-white shadow-sm">
        <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <img src="/logo.png" alt="Vectorial Logo" className="h-8 w-auto mr-2" />
              <span className="text-3xl font-bold text-blue-800">Vectorial</span>
            </div>
            <div className="flex space-x-8">
              <a href="#services" className="text-gray-700 hover:text-indigo-600">Services</a>
              <a href="#about" className="text-gray-700 hover:text-indigo-600">About</a>
              <a href="#client-success-stories" className="text-gray-700 hover:text-indigo-600">Client Success Stories</a>
              <a href="#contact" className="text-gray-700 hover:text-indigo-600">Contact</a>
            </div>
          </div>
        </nav>
      </header>

      <main>
        <section className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center">
            <h1 className="font-extrabold text-gray-900 text-4xl sm:text-4xl md:text-4xl lg:text-5xl">
              AI and Software Development Services
            </h1>
            <p className="mt-3 max-w-xl mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-2xl">
              At Vectorial, we transform ideas into solutions. <br />
              We specialize in <strong>generative AI</strong>, <strong>machine learning training and inference</strong>, and <strong>full-stack software development</strong>.
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <a href="#contact" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10">
                  Get Started
                  <ArrowRight className="ml-2" size={20} />
                </a>
              </div>
            </div>
          </div>
        </section>

        <section id="services" className="bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center">Our Services</h2>
            <div className="mt-10">
              <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
                <div className="flex flex-col items-center">
                  <Brain className="h-12 w-12 text-indigo-600" />
                  <h3 className="mt-6 text-lg font-medium text-gray-900">AI Solutions</h3>
                  <p className="mt-2 text-base text-gray-500 text-center">
                    Custom <strong>generative AI</strong> and <strong>machine learning</strong> solutions tailored to your business needs.
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <Code className="h-12 w-12 text-indigo-600" />
                  <h3 className="mt-6 text-lg font-medium text-gray-900">Software Development</h3>
                  <p className="mt-2 text-base text-gray-500 text-center">
                    Scalable and secure <strong>software</strong> solutions for <strong>web applications</strong> and <strong>enterprise systems</strong>.
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <Zap className="h-12 w-12 text-indigo-600" />
                  <h3 className="mt-6 text-lg font-medium text-gray-900">Integration Services</h3>
                  <p className="mt-2 text-base text-gray-500 text-center">
                    Seamless integration of <strong>AI</strong> and <strong>software</strong> solutions into your existing infrastructure.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about" className="bg-gray-100">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center">About Us</h2>
            <p className="mt-4 max-w-5xl mx-auto text-center text-xl text-gray-500">
              We are a team of passionate <strong>software developers</strong>, <strong>AI engineers</strong>, and <strong>data scientists</strong> dedicated to delivering innovative solutions that drive business growth and technological advancement.<br /><br />
              Our team leverages extensive experience from top industry players such as <strong>AWS</strong>, <strong>Oracle</strong>, <strong>Visa</strong>, <strong>Home Depot</strong>, and <strong>Boston Dynamics</strong> to provide cutting-edge, custom-tailored AI and software solutions to suit your specific needs.<br /><br />
              Whether you are an <strong>early-stage startup</strong> or an <strong>established enterprise</strong>, we are fixated on your success.
            </p>
          </div>
        </section>

        <section id="client-success-stories" className="bg-white">
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">Client Success Stories</h2>
            <div className="relative px-12"> {/* Added padding here */}
              <div className="overflow-hidden">
                <div className="flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentSlide * 100}%)` }}>
                  {projects.map((project, index) => (
                    <div key={index} className="w-full flex-shrink-0 px-4">
                      <h3 className="text-xl font-semibold text-gray-900 mb-2">{project.title}</h3>
                      <p className="text-gray-600">{project.description}</p>
                    </div>
                  ))}
                </div>
              </div>
              <button onClick={prevSlide} className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md">
                <ChevronLeft className="text-gray-600" />
              </button>
              <button onClick={nextSlide} className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md">
                <ChevronRight className="text-gray-600" />
              </button>
            </div>
            <div className="flex justify-center mt-4">
              {projects.map((_, index) => (
                <button
                  key={index}
                  className={`h-2 w-2 rounded-full mx-1 ${index === currentSlide ? 'bg-indigo-600' : 'bg-gray-300'}`}
                  onClick={() => setCurrentSlide(index)}
                />
              ))}
            </div>
          </div>
        </section>

        <section id="contact" className="bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center">Contact Us</h2>
            <div className="mt-8 max-w-md mx-auto">
              <form className="grid grid-cols-1 gap-6" action="https://formspree.io/f/xgvwvnbo" method="POST">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                  <input type="text" name="name" id="name" className="mt-1 block w-full border-2 border-gray-200 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2" />
                </div>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                  <input type="email" name="email" id="email" className="mt-1 block w-full border-2 border-gray-200 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2" />
                </div>
                <div>
                  <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea name="message" id="message" rows="4" className="mt-1 block w-full border-2 border-gray-200 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 px-3 py-2"></textarea>
                </div>
                <div>
                  <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <p className="text-center text-base text-gray-400">
            © 2024 Vectorial, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;